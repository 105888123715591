body {
  font-family: sans-serif, system-ui;
  font-weight: 900;
  line-height: 1.25;
  font-size: 16px;
}

.spacing {
  letter-spacing: .05em;
}

.spacing li {
  text-transform: uppercase;
}

a:link, a:visited, a:hover, a:active {
  color: inherit;
  text-decoration: none;
}

img {
  /* margin-bottom: -5px; */
  width: 100%;
}

@media screen and (min-width: 1401px) {
  .enlarge-text, .enlarge-text button {
    font-size: 4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .enlarge-text, .enlarge-text button {
    font-size: 3rem;
  }
}

@media screen and (max-width: 1024px) {
  .enlarge-text {
    font-size: 1.5em !important;
    margin-bottom: .5rem;
  }
}

/* VIDEO */

#vidContainer {
  position: fixed;
  object-fit: cover;
  min-width: 100%; 
  min-height: 100%;
  overflow: hidden;
  z-index: -100;  
  background: #c0c0c0;
}

/* Navbar */

.navbar {
  text-transform: uppercase;
  font-size: 3em;
  color: #39FF14;
  padding: 0.5rem 0;

  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
}

@media screen and (min-width: 660px) {
  .navbar {
    padding: 0.5rem 0.75em;
  }
}

.navbar a {
  text-decoration: none;
}

@media only screen and (max-width: 660px) {
  .navbar { font-size: 2em; }
}

.navbar:hover {
  color: #1fc700;
}

.floatMenu {
  position: fixed;
}

.impact-container {
  display: flex;
  position: fixed;
  bottom: 0;
  background-color: #39FF14;
  font-size: 1em;
  padding: 0.25em;
  z-index: 15;
}

@media only screen and (min-width: 660px) {
  .impact-container {
    bottom: auto;
    float: right;
    background-color: #39FF14;
    writing-mode: vertical-rl;
    text-orientation: mixed;


    position: fixed;
    z-index: 15;
    right: 0;
    top: 0;

  }
}

.impact-container p {
  display: flex;
}

.close-button {
  float: right;
  background-color: white;
  margin: 0.25em;


  background-color: white;
    margin: 0.25em;
    flex-grow: 0;
    flex-shrink: 1;
    align-self: center;
    justify-self: flex-end;

    padding: 0.35em;
    border-radius: 0.25em;
    cursor: pointer;
}

/* Text */

ul { 
  margin: 0; 
  padding-left: 0;
}
li {    
  overflow: hidden;  
  hyphens: auto;
}
ul li a { text-decoration: none; }

.fixed-scroll {
  text-transform: uppercase;
  color: #070707;
  hyphens: auto;
}

@media only screen and (max-width: 660px) {
  .fixed-scroll { font-size: 2.25em; }
}

.blackText {
  color: black;
}

li a:hover {
  color: #39FF14;
}


/* Landscape */
@media only screen and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {     
  .fixed-scroll { font-size: 2.5em; }
  .navbar { font-size: 2.5em; }
}

.mobileProduct {
  width:100vw;
  height:100vh;
  display:table-cell;
  vertical-align:middle;
  text-align:center;
}

.content {
  grid-area: c;
  background-color: #fff;
}

.products_container {
  display: grid;
  grid-row-gap: 3em;
  grid-template-columns: 2fr 2fr;
}

@media screen and (min-width: 660px) {
  .products_container {
    padding: 0 10%;
    display: grid;
    grid-column-gap: 5em;
    grid-template-columns: 2fr 2fr;
  } 
}

.card {
  border: 0;
  display: grid;
  grid-gap: 15px;
}

.card-enter {
  flex-direction: column;
  flex-basis: 100%;
  display: flex;
  align-items: flex-end;
  margin-top: -4px;
}

@media screen and (min-width: 660px) {
  .card-enter {
    margin-top: -10px;
  }
}

.card-title {
  flex-direction: column;
  flex-basis: 80%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
}

.card-title span {
  margin-bottom: -13px;
}

.card-image-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  color: #39FF14;
  font-size: 3em;
}

@media only screen and (max-width: 660px) {
  .card-image-wrapper { font-size: 2em; }
  .card-title { flex-basis: 50%; }
  .card-title span {
    margin-bottom: -10px;
    margin-left: -2px;

  }
}

.wrapper {
  max-width: 100vw;
  margin: 0 auto;
  grid-template-areas: 
  "c c c c c c c c c c c c" 
}

@media screen and (max-width: 1024px) {
  .wrapper {
    max-width: 100vw;
    margin: 0 auto;
    grid-template-areas: 
    "c c c c c c c c c c c c" 
  }

  .products_container {
    grid-template-columns: 1fr;
  }
}
/* 
.mobileProductImg {
  max-width:100%;
  height:auto;
  object-fit: cover;
  max-height:100%;
} */

/* CAROUSEL */

.carousel-caption {
  margin-left: 0;
  margin-right: 0;
}

.dollar-container {
  position: absolute;
  bottom: 20px;
  left: 0;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #39FF14;
  text-align: center;
  margin-left: 10rem;
}

.purchase-container {
  position: absolute;
  right: 0;
  bottom: 20px;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #39FF14;
  text-align: center;
  margin-right: 10rem;
}

.carousel-control-prev {
  color: #39FF14;
  font-size: 3rem;
}

.carousel-control-next {
  color: #39FF14;
  font-size: 3rem;
}

@media screen and (min-width: 660px) {
  .carousel-control-prev {
    margin-left: 10rem;
  }
  
  .carousel-control-next {
    margin-right: 10rem;
  } 
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='chartreuse' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='chartreuse' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
  transform: scale(-1, 1) translate(0, 0)
}
  
@media screen and (min-width: 320px) and (max-width: 1024px) and (orientation: portrait) {
  .purchase-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    margin: 0 auto;
  } 

  .purchase-container h3 button, .purchase-container h3 {
    font-size: 1.5em;
    letter-spacing: .2rem;
  }

  .carousel-caption, .dollar-container {
    display: none;
  }
}

@media screen and (min-width: 320px) and (max-width: 1024px) and (orientation: landscape) {
  .carousel-caption, .dollar-container {
    display: none;
  }

  .purchase-container h3,  .purchase-container h3 button {
    font-size: 1.5em;
    letter-spacing: .2rem;
  }

  .purchase-container {
    margin: 0 auto;
    position: fixed;
    bottom: 0;
    left: 0;
  }
}

@media screen and (min-width: 1025px) {
  .mobile-dollar-container {
    display: none;
  }
}

@media screen and (min-width: 320px) and (orientation: landscape) {
  .purchase-container button {
    line-height: 3.6rem;
  }
  .m-carousel a span {
    height: 3rem;
    width: 3rem;
    color: #39FF14;
  }
}

.purchase-container button {
  background: transparent;
  color: #39FF14;
  border: none;
}

.contentText {
  padding: 1.5rem;
  font-size: 1.5rem;
  font-size: 2rem;
}

@media screen and (min-width: 660px) {
  .contentText {
    padding: 0 11rem;
    font-size: revert;
    font-size: 4em;
  }
}